
// ComponentModal.js
import React from 'react';
import { Modal } from '@material-ui/core'; // Ensure @material-ui/core is installed
import './ComponentModal.scss'; // Ensure to import your SCSS file here

export default function ComponentModal({ open, handleClose, Component }) {
  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <div className="modal-overlay" onClick={handleClose}>
        <div className="modal-content">
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
          <div className="modal-inner-content">
            {Component && <Component />}
          </div>
        </div>
      </div>
    </Modal>
  );
}