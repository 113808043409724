import React from "react";
import "./App.scss";


const LegalNotice = () => {

  return (
    
    <div style={{ height: '100%', overflow: 'scroll' }}> 
      <a href="/">
    <img  src={require("../src/components/header/logo.png")} alt={"Test"} width="100px" height="50px"  />
    </a>
      <h1>Legal Notice</h1>
    <h3>mat4nrg GmbH</h3>
    <h3>Burgstätterstr. 42</h3>
    <h3>38678 Clausthal-Zellerfeld</h3>
    <h3>info@mat4nrg.de</h3>
    <h3>Represented by: Nikolaos Argirusis</h3>
    
    <h2>Registration entry:</h2>
    <h3>Registered in the commercial register.</h3>
    <h3>Registration court: Braunschweig</h3>
    <h3>Registration number: HRB 207892</h3>
    
    <h2>VAT ID:</h2>
    <h3>VAT identification number according to Section 27a of the Sales Tax Law: DE325122921</h3>
    
    <h2>Disclaimer – legal notices</h2>
    <p><b>Warning about content:</b>
    The free and freely accessible content of this website was created with the greatest possible care.<br /> However, the provider of this website assumes no liability for the accuracy and timeliness of the free and freely accessible<br/> journalistic advice and news provided.<br/> Contributions marked by name reflect the opinion of the respective author and not always the opinion of the provider. Simply accessing the free<br/> and freely accessible content does not create any contractual relationship between the user and the provider, as the provider has no intention<br/> of being legally bound.
    <br/><b>External links:</b>
    This website contains links to third-party websites (“external links”). These websites are subject to<br/> the liability<br/> of the respective operators. When the external links were first linked, the provider checked the external <br/>content to see whether there were any legal<br/> violations. At that time, no legal violations were apparent. The provider has no influence on the current and future design and content <br/>of the linked pages. The setting of external links does not mean that the provider adopts the content behind the reference or link as its own.<br/> Constant monitoring of external links is unreasonable for the provider without concrete evidence of legal violations. However, if legal violations are known, such external<br/> links will be deleted immediately.
    <br/><b>Copyright and ancillary copyrights:</b>
    The content published on this website is subject to German copyright and ancillary copyright law. Any <br/>use not permitted<br/> by German copyright and ancillary copyright law requires the prior written consent of the provider or <br/>the respective rights holder. This applies in <br/>particular to the reproduction, editing, translation, storage, processing or reproduction of content in databases or other electronic<br/> media and systems. Content and rights of third parties are marked as such. The unauthorized reproduction or distribution of individual content <br/>or entire pages is not permitted and is punishable. Only the production of copies and downloads for personal, <br/>private and non-commercial use is permitted.
    The display of this website in external frames is only permitted with written permission.
    <br/><b>Special terms of use:</b>
    If special conditions for individual uses of this website deviate from the aforementioned paragraphs, this<br/> will be<br/> expressly stated at the appropriate point. In this case, the special terms of use apply in each individual <br/>case.
    Source: Imprint sample from JuraForum.de</p>
    
    <h2>Data protection:</h2>
    
    <p>We will inform you below in accordance with the statutory requirements of data protection law (in particular<br/> in accordance with the BDSG n.F. and the European General Data Protection Regulation 'DS-GVO') about the type, scope<br/> and purpose of the processing of personal data by our company. This privacy policy also applies to our websites and social media profiles. Regarding the definition of terms such as <br/>"personal data" or "processing", we refer to Art. 4 GDPR. Name and contact details of the person responsible
    <br/>Our person responsible (hereinafter "person responsible") within the meaning of Art. 4 No. 7 GDPR is:</p><br/>
    
    <h3>mat4nrg GmbH</h3>
    <h3>Burgstätterstr. 42</h3>
    <h3>38678 Clausthal-Zellerfeld</h3>
    <h3>info@mat4nrg.de</h3>
    <h3>Represented by: Nikolaos Argirusis</h3>
    
    <b>Types of data, purposes of processing and categories of data subjects</b>
      </div>
  );
  
}

export default LegalNotice;

