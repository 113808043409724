import React from 'react';
import "../../App.scss";

function NovelMaterialsforEnergyApplications() {
  return (
    <div style={{ height: '100%', overflow: 'scroll' }}> 
      <a href="/">
    <img  src={require("../header/logo.png")} alt={"Test"} width="100px" height="50px"  />
    </a>
    <p>Nowadays, there is an enormous need for novel materials for a wide range of energy applications. Areas of application can be:<br/>

<b>Hydrogen generation and storage</b><br/>
<b>Fuel cell technology</b><br/>
<b>Solar Power</b><br/>
<b>Wind Energy</b><br/>
<b>Increasing energy efficiency in buildings and integration of alternative energy sources</b><br/>
<b>Increasing the efficiency of power plants</b><br/>
<b>Geothermal applications</b><br/>
<b>Pumped storage plants in hydropower</b><br/></p>
      </div>
  );
}

export default NovelMaterialsforEnergyApplications;