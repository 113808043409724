import React, { useContext, useState } from "react";
import "./Achievement.scss";
import AchievementCard from "../../components/achievementCard/AchievementCard";
import { achievementSection } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import ComponentModal from "../../components/achievementCard/ComponentModal.js";

export default function Achievement() {
  const { isDark } = useContext(StyleContext);
  const [open, setOpen] = useState(false);
  const [Component, setComponent] = useState(null);

  if (!achievementSection.display) {
    return null;
  }

  const handleOpen = (Component) => {
    setComponent(() => Component);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setComponent(null);
  };

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="achievements">
        <div className="achievement-main-div">
          <div className="achievement-header">
            <h1
              className={
                isDark
                  ? "dark-mode heading achievement-heading"
                  : "heading achievement-heading"
              }
            >
              {achievementSection.title}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode subTitle achievement-subtitle"
                  : "subTitle achievement-subtitle"
              }
            >
              {achievementSection.subtitle}
            </p>
          </div>
          <div className="achievement-cards-div">
            {achievementSection.achievementsCards.map((card, i) => {
              return (
                <AchievementCard
                  key={i}
                  isDark={isDark}
                  cardInfo={{
                    title: card.title,
                    description: card.subtitle,
                    image: card.image,
                    imageAlt: card.imageAlt,
                    footer1: card.footerLink1,
                    footer2: card.footerLink2,
                    footer3: card.footerLink3,
                    footer4: card.footerLink4,
                    footer5: card.footerLink5,
                  }}
                  handleOpen={handleOpen}
                />
              );
            })}
          </div>
        </div>
      </div>
      <ComponentModal open={open} handleClose={handleClose} Component={Component} />
    </Fade>
  );
}
