/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file
import { Link } from 'react-router-dom';
import LegalNotice from './LegalNotice';
import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/mymy.json"; // Rename to your file name for custom animation
import Component1 from '../src/components/achievementCard/Component1.js';
import Catalyst from './components/achievementCard/Catalyst.js';
import photoCatalyst from './components/achievementCard/photoCatalyst.js';
import electroCatalyst from './components/achievementCard/electroCatalyst.js';
import testCatalyst from './components/achievementCard/testCatalyst.js';
import plus from './components/achievementCard/plus.js';
import NovelMaterialsforEnergyApplications from './components/achievementCard/NovelMaterialsforEnergyApplications.js';


// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "MAT4NRJ",
  title: "MAT4NRG",
  subTitle: (
    "mat4nrg – Society for Materials and Energy Applications (GmbH) is a spin-off from three universities. Mat4nrg GmbH specializes in materials preparation, characterisation, and application. We offer their production and characterization as well as the investigation of possible fields of application with special focus on materials for energy and environmental applications."
  ),
  resumeLink:false, // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "",
  linkedin: "",
  gmail: "",
  gitlab: "",
  facebook: "",
  medium: "",
  stackoverflow: "",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: false // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What do we do?",
  subTitle: "We are specializing on: Development, testing and application of components for stationary and mobile energy production and storage, in particular for batteries and fuel cells. Production and storage of energy from renewable and alternative energy sources. Energy technology with focus on storage, process and thermal engineering are of great importance for MAT4NRG. We are working on ultrasound assisted production of nanomaterials, the characterization of materials with regard to their chemical, electrical and mechanical properties, development and licensing of production methods and processes for polymers as well as ceramic and metallic nanomaterials, composite materials and catalysts for catalytic applications with focus on photocatalysis.  Sonochemical and sono-electrochemical methods are used for the preparation of nanosized materials for application e.g. as electrocatalysts.Mat4nrg is active in environmental applications such as the degradation and biotechnological digestion of microplastics as well as in the field of microbial fuel cells.Last but not least, we have long expertise in the physical surface analysis and atmospheric plasma activation of materials. Other relevant activities are surface modification of ceramics, transport properties in oxide ceramic materials and electrochemical deposition methods as well as corrosion.",
  skills: [
    emoji(
      "⚡Ultrasonics (production of nanomaterials, catalysts, etc.)."),
      emoji("⚡Characterization of materials with regard to their chemical, electrical and mechanical properties."),
      emoji("⚡Development and licensing of production methods and processes for polymers as well as ceramic and metallic nanomaterials as well as composite materials and catalysts for catalytic applications, photocatalysis, ultrasound chemistry and environmental technology."),
      emoji("⚡Development, testing and application of components for stationary and mobile energy production and storage, in particular for batteries and fuel cells."),
      emoji("⚡Electric Mobility."),
      emoji("⚡Production and storage of energy from renewable and alternative energy sources."),
      emoji("⚡Energy Technology."),
      emoji("⚡Energy storage."),
      emoji("⚡Energy Process Engineering."),
      emoji("⚡Thermal Engineering."),
      emoji("⚡Development of electronic circuits."),
      emoji("⚡Development, licensing and trading electrical, chemical and electrochemical sensors."),
      emoji("⚡Prototype development and small batch production of components."),
      emoji("⚡Trade with developed materials as well as starting materials for their production."),
      emoji("⚡Preparation of expertise opinions, reports, calculations and simulations to all of the above mentioned applications."),
      emoji("⚡Big Data in Materials, Energy and Environmental Sciences."),
  
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      //skillName: "html-5",
      //fontAwesomeClassname: "fab fa-html5"
    },
    {
      //skillName: "css3",
      //fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      //skillName: "sass",
      //fontAwesomeClassname: "fab fa-sass"
    },
    {
      //skillName: "JavaScript",
      //fontAwesomeClassname: "fab fa-js"
    },
    {
      //skillName: "reactjs",
      //fontAwesomeClassname: "fab fa-react"
    },
    {
      //skillName: "nodejs",
      //fontAwesomeClassname: "fab fa-node"
    },
    {
      //skillName: "swift",
      //fontAwesomeClassname: "fab fa-swift"
    },
    {
      //skillName: "npm",
      //fontAwesomeClassname: "fab fa-npm"
    },
    {
      //skillName: "sql-database",
      ///fontAwesomeClassname: "fas fa-database"
    },
    {
      //skillName: "aws",
      //fontAwesomeClassname: "fab fa-aws"
    },
    {
      //skillName: "firebase",
      //fontAwesomeClassname: "fas fa-fire"
    },
    {
      //skillName: "python",
      //fontAwesomeClassname: "fab fa-python"
    },
    {
      //skillName: "docker",
      //fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Harvard University",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "September 2017 - April 2019",
      desc: "Participated in the research of XXX and published 3 papers.",
      descBullets: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      ]
    },
    {
      schoolName: "Stanford University",
      logo: require("./assets/images/stanfordLogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2013 - April 2017",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section
// portfolio.js
// portfolio.js
const workExperiences = {
  display: true, // Set it to true to show the work experiences section
  experience: [
    {
      role: "Tailor made materials",
      company: "",
      companylogo: require("./assets/images/becher.png"),
      desc: "We use all state-of-the-art methods for the preparation of materials according to customers demands. All followed by thermal sintering (if necessary) and control of chemical as well as phase purity.",
      descBullets: [
        "Mixed oxide",
        "Pecchini",
        "Sol-gel",
        "Sonochemistry",
        "Electrochemistry",
        "Sono-electrochemistry",
      ],
     
    },
    {
      role: "Characterization of materials according to your demands",
      company: "",
      companylogo: require("./assets/images/becher.png"),
      date: "",
      desc: "Chemical purity of materials.",
      descBullets: [
        "Phase purity of materials",
        "Transport properties in condensed matter",
        "Diffusion in condensed matter-Permeation studies (oxygen, hydrogen, etc)",
        "Electrochemical properties at temperatures up to 1200 °C",
        "Conductivity measurements",
        "Impedance spectroscopy",
        "Thermochemical properties",
        "Thermal expansion",
        "Thermal cycling at elevated temperatures",
        "Thermal conductivity"
      ],
      // This one does not have popupImages
    },
    {
      role: "Materials Properties Measurements",
      company: "",
      companylogo: require("./assets/images/becher.png"),
      date: "",
      desc: "Examples of the Measurements",
      descBullets: [
        "Thermal cycling of composite materials",
        "Electrophoretic deposition(EPD)",
        "Electrophoretic deposition(EPD) of thermal and oxygen barrier coatings on alloys and C/C composites.",
        "Characterisation of Ni-based alloy with EPD coating after thermal treatment",
        "Thermal cycling facilities up to 1600°C"
      ],
      popupImages: [ // Optional additional images
        require("./assets/images/image-3-1024x541.png"),
        require("./assets/images/image-5-1024x618.png"),
        require("./assets/images/image-6-1024x597.png"),
        require("./assets/images/image-7.png"),
      ]
    },
    // Add more experiences as needed...
  ]
};


const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// portfolio.js
const bigProjects = {
  display: true, // Set false to hide this section, defaults to true if omitted.
  title: "Projects",
  subtitle: "Some of our prominent projects",
  projects: [
    {
      image: require("./assets/images/retreadables.png"),
      projectName: "",
      projectDescs: [
        "Circular economy ecosystem to Recover, Recycle and Re-use F-gases contributing to the depletion of greenhouse gases [LIFE Retradeables]",
        "Action C2: F-gas identification & recycling prototypes",
        "This project has received funding from the LIFE Programme of the European Union under grant agreement","LIFE19 CCM/AT 001226 - LIFE Retradeables"
      ],
      additionalImage: require("./assets/images/Image1.png"), // Add this line
      footerLink: [
        { name: "Retradeables Link", url: "https://retradeables.com" },
        { name: "", url: "" }
      ]
    },
    // More projects...
  ]
};
const achievementSection = {
  display: true,
  title: "Reasearch Fields",
  subtitle: "",
  achievementsCards: [
    {
      title: "Catalysis",
      subtitle: "Achievement Subtitle 1",
      image: require("./assets/images/becher.png"),
      imageAlt: "Achievement Image Alt",
      footerLink1: [
        { name: "Catalysts Preperation", component: Catalyst },
      ],
      footerLink2: [
        { name: "Photocatalysis", component: photoCatalyst },
      ],
      footerLink3: [
        { name: "Electrocatalysis", component: electroCatalyst },
      ],
      footerLink4: [
        { name: "Catalytic Tests", component: testCatalyst },
      ],
      footerLink5: [
        { name: "+", component: plus },
      ],
    },
    {
      title: "Energy Applications",
      subtitle: "Achievement Subtitle 1",
      image: require("./assets/images/becher.png"),
      imageAlt: "Achievement Image Alt",
      footerLink1: [
        { name: "Novel Materials for Energy Applications", component: NovelMaterialsforEnergyApplications},
      ],
      footerLink2: [
        { name: "Solid Oxide Fuel Cells (SOFCs)", component: photoCatalyst },
      ],
      footerLink3: [
        { name: "Microbial Fuel Cells (MFCs)", component: photoCatalyst },
      ],
      footerLink4: [
        { name: "Energy Storage", component: photoCatalyst },
      ],
      footerLink5: [
        { name: "CO2 Conversion to Fuels and Chemicals", component: photoCatalyst },
      ],
    },
    {
      title: "Environment",
      subtitle: "Achievement Subtitle 1",
      image: require("./assets/images/becher.png"),
      imageAlt: "Achievement Image Alt",
      footerLink1: [
        { name: "Plastics Degradation", component: photoCatalyst },
      ],
      footerLink2: [
        { name: "Advanced Oxidation Processes (AOPs)", component: photoCatalyst },
      ],
      footerLink3: [
        { name: "Microbial FCs", component: photoCatalyst },
      ],
      footerLink4: [
        { name: "+", component: plus },
      ],
      footerLink5: [
        { name: "+", component: plus },
      ],
    },
  ],
};


// Blogs Section

const blogSection = {
  title: "Legal Notice",
  subtitle:
    "",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: '/LegalNotice',
      title: "Click here to read more about the Legal Notice",
      description:
        ""
    },
  ],
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "Legal Notice",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false// Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Us ☎️"),
  subtitle:
    ["mat4nrg GmbH",
      "Burgstätterstr.42", 
      "38678 Clausthal-Zellerfeld",
       "Germany"],
  number: "+49 5323 830863",
  email_address: "info@mat4nrg.de"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
